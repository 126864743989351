import React from 'react';
import { Box, Card, Chip, Typography } from '@mui/material';
import './css/footer.css';
import { useNavigate } from 'react-router-dom';
import appstore from './../assets/appstore.png';
import playstore from './../assets/playstore.png';
import MainWidthWrapper from './MainWidthWrapper';

const Footer = () => {
  const navigate = useNavigate();
  return (
    <Box
      sx={{
        margin: 'auto',
        width: { md: '55%', mg: '100%', xs: '100%' },
        backgroundColor: '#fff',
        height: '80vh',
      }}
    >
      <div className="footer">
        <div className="section_padding">
          <div className="links">
            <div className="footer-links-div-1">
              <h4 className="h-1">Links</h4>
              <div className="div-1">
                <a
                  className="p-1"
                  onClick={() => {
                    window.scrollTo(0, 0);
                    navigate('/');
                  }}
                  style={{ cursor: 'pointer' }}
                >
                  <p>Matches</p>
                </a>
                <a
                  className="p-2"
                  onClick={() => {
                    window.scrollTo(0, 0);
                    navigate('/tournaments');
                  }}
                  style={{ cursor: 'pointer' }}
                >
                  <p>Tournaments</p>
                </a>
                <a
                  className="p-3"
                  onClick={() => {
                    window.scrollTo(0, 0);
                    navigate('/team-creation');
                  }}
                  style={{ cursor: 'pointer' }}
                >
                  <p>Teams</p>
                </a>
              </div>
            </div>

            <div className="footer-links-div-2">
              <h4 className="h-2">Gully Ball</h4>
              <div className="div-2">
                <a className="p-4" onClick={() => navigate('/about-us')} style={{ cursor: 'pointer' }}>
                  <p>About Us</p>
                </a>
              </div>
            </div>
            <div className="footer-links-div-3">
              <h4 className="h-3">Help</h4>
              <div className="div-3">
                <a className="p-5">
                  <p
                    onClick={() => {
                      window.open('https://wa.me/919958162727?text=Hi,%20I%20need%20support!', '_blank');
                    }}
                  >
                    Chat with Us
                  </p>
                </a>
              </div>
            </div>
            <div className="footer-links-div-4">
              <h4 className="h-4">Legal</h4>
              <div className="div-4">
                <a className="p-6" onClick={() => navigate('/terms-of-service')} style={{ cursor: 'pointer' }}>
                  <p>Terms of Services</p>
                </a>
                <a className="p-6" onClick={() => navigate('/privacy-policy')} style={{ cursor: 'pointer' }}>
                  <p>Privacy Policy</p>
                </a>
              </div>
            </div>
            <div className="footer-links-div-5">
              <p className="app">Get the Gully Ball App</p>
              <div className="socialmedia">
                <a href="https://play.google.com/store/apps/details?id=com.myysports.gully6" className="p-8">
                  <img src={playstore} alt="Get it on Google Play" />
                </a>
                <a href="https://apps.apple.com/in/app/gully-ball-street-cricket/id6608972577" className="p-9">
                  <img src={appstore} alt="Download on the App Store" />
                </a>
              </div>
            </div>
          </div>
          <div className="footer-copyright">
            <p>&copy; {new Date().getFullYear()} Gully Ball</p>
          </div>
        </div>
      </div>
    </Box>
  );
};

export default Footer;
