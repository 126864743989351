import React, { useState } from 'react';
import { Box, Button, Typography } from '@mui/material';
import ScoreTable from '../ScoreTable';

const LiveScoreInningTabs = ({ currScore, isLive }) => {
  const [activeTab, setActiveTab] = useState(0);

  // Safely access teamScore and handle missing data
  const teamKeys = currScore?.teamScore ? Object.keys(currScore.teamScore) : [];

  const handleButtonClick = (index) => {
    setActiveTab(index);
  };

  return (
    <Box>
      <Box
        display="flex"
        justifyContent="center"
        width="100%"
        marginBottom={3}
        sx={{
          overflowX: 'auto',
          whiteSpace: 'nowrap',
          paddingX: 1,
        }}
      >
        {teamKeys.length > 0 ? (
          teamKeys.map((teamKey, index) => (
            <Button
              key={teamKey}
              onClick={() => handleButtonClick(index)}
              sx={{
                backgroundColor: index === activeTab ? 'black' : '#F3F3F3',
                color: index === activeTab ? 'white' : 'black',
                borderRadius: 2,
                minWidth: '150px',
                marginX: 1,
                '&:hover': {
                  backgroundColor: index === activeTab ? 'black' : '#F3F3F3',
                },
              }}
            >
              <Typography
                sx={{
                  color: index === activeTab ? '#ffffff' : '#000000',
                  textTransform: 'uppercase',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  fontSize: '12px',
                  '@media screen and (max-width: 600px)': {
                    maxWidth: '60%',
                  },
                }}
              >
                {teamKey}
              </Typography>
            </Button>
          ))
        ) : (
          <Typography>No team data available</Typography>
        )}
      </Box>

      <Box pb={2}>
        <ScoreTable
          inning={null}
          isLive={isLive}
          currOver={currScore?.currOverDetail ?? null}
          teamScore={currScore?.teamScore?.[teamKeys[activeTab]] ?? null}
        />
      </Box>
    </Box>
  );
};

export default LiveScoreInningTabs;
