import React from 'react';
import { Link } from 'react-router-dom';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  centeredLinks: {
    textAlign: 'center',
    fontSize: '13px',
    paddingTop: '5px',
  },
  link: {
    margin: '0 8px',
    textDecoration: 'none',
    color: 'inherit',
  },
}));

const CenteredLinks = ({ links }) => {
  const classes = useStyles();

  return (
    <div className={classes.centeredLinks}>
      {links.map((link, index) => (
        <React.Fragment key={index}>
          <Link to={link.url} className={classes.link}>
            {link.text}
          </Link>
          {index < links.length - 1 && <span> | </span>}
        </React.Fragment>
      ))}
    </div>
  );
};

export default CenteredLinks;
