import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setFirebaseUser, toggleAuthWallOff } from '../../redux/auth/auth.slice';
import { existingUser, signUp, login } from '../../redux/auth/auth.action';
import { GoogleOAuthProvider, GoogleLogin } from '@react-oauth/google';
import { jwtDecode } from 'jwt-decode';
import { getUserIdentifier } from '../../helpers/userUuid';
import ReactGA from 'react-ga4'; // Import GA
import { useLocation } from 'react-router-dom';

const SocialLogin = () => {
  // const GOOGLE_CLIENT_ID = process.env.REACT_APP_GOOGLE_CLIENT_ID;
  const clientID = process.env.REACT_APP_GOOGLE_CLIENT_ID;
  // const GOOGLE_CLIENT_ID = '878111994985-bbej3p9dh0kdljn26ulh0jvh1uana8uv.apps.googleusercontent.com';
  const GA_TRACKER_ID = process.env.REACT_APP_GA_TRACKER_ID;
  const { nextAuthAction, firebaseUser } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  // Initialize GA
  useEffect(() => {
    ReactGA.initialize(GA_TRACKER_ID);
  }, [GA_TRACKER_ID]);

  // Handle Google OAuth success
  const handleGoogleSuccess = (response) => {
    const result = jwtDecode(response.credential);
    dispatch(setFirebaseUser(result)); // Store user in Redux
  };
  // Handle actions based on nextAuthAction (LOGIN or SIGNUP)
  useEffect(() => {
    if (firebaseUser) {
      const { name, email } = firebaseUser;
      const exisitingUserPayload = {
        userIdentity: email,
        claimAssist: false,
        isIdVerified: true,
      };
      dispatch(existingUser(exisitingUserPayload));
    }
  }, [firebaseUser, dispatch]);
  useEffect(() => {
    if (nextAuthAction?.action && firebaseUser) {
      const action = nextAuthAction?.action?.toUpperCase();
      switch (action) {
        case 'LOGIN': {
          const loginPayload = buildLoginPayload(firebaseUser);
          dispatch(login(loginPayload));
          dispatch(toggleAuthWallOff());
          // Send GA event for SignUp
          ReactGA.event({
            category: 'User',
            action: 'SignUp',
            label: 'Google OAuth',
          });
          break;
        }
        case 'SIGNUP': {
          const signupPayload = buildSignupPayload(firebaseUser);
          dispatch(signUp(signupPayload));
          dispatch(toggleAuthWallOff());
          break;
        }
        default:
          console.log('Unknown action');
          break;
      }
    }
  }, [nextAuthAction, dispatch]);
  // Build signup payload for Firebase users
  const buildSignupPayload = (userInfo) => {
    if (userInfo) {
      const { name, email } = userInfo;
      // const method = methods[methods.length - 1]; // apple, google, whatsapp, etc
      const sourceMeta = {
        method: 'GOOGLE',
        channel: 'FIREBASE',
        idField: 'email',
      };
      const signupPayload = {
        newUser: {
          userName: '',
          password: '',
          role: 'user',
          name: {
            firstName: name,
            lastName: '',
          },
          email: email,
        },
        fcmDeviceToken: '',
        signupSource: {
          serviceName: 'GOOGLE',
          sourceMeta,
        },
        platform: 'web',
        deviceId: getUserIdentifier(),
      };
      return signupPayload;
    }
    return null;
  };
  // Build login payload for Firebase users
  const buildLoginPayload = (userInfo) => {
    if (userInfo) {
      const { email } = userInfo;
      const loginPayload = {
        identity: email,
      };
      return loginPayload;
    }
    return null;
  };
  return (
    <GoogleOAuthProvider clientId={clientID}>
      <GoogleLogin
        theme="filled"
        shape="standard"
        type="standard"
        logo_alignment="center"
        ux_mode="popup"
        onSuccess={(response) => handleGoogleSuccess(response)}
        onError={() => console.log('Google Sign-in Error')}
      />
    </GoogleOAuthProvider>
  );
};
export default SocialLogin;
