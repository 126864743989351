import React, { useState } from 'react';
import MainWidthWrapper from '../../components/MainWidthWrapper';
import { RiMessage2Line } from 'react-icons/ri';
import { IoLocationOutline } from 'react-icons/io5';
import { sendEmail } from '../../redux/auth/auth.action';
import { useDispatch } from 'react-redux';
const SupportPage = () => {
  const [formData, setFormData] = useState({
    fullName: '',
    contactNumber: '',
    email: '',
    emailSubject: '',
    message: '',
  });
  const dispatch = useDispatch();
  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    // Construct the mailto URL
    const { email, emailSubject, message, fullName, contactNumber } = formData;
    const reqPayload = {
      from: email,
      name: fullName,
      subject: emailSubject,
      contact: contactNumber,
      query: message,
    };
    dispatch(sendEmail(reqPayload));
    setFormData({
      fullName: '',
      contactNumber: '',
      email: '',
      emailSubject: '',
      message: '',
    });
  };
  return (
    <MainWidthWrapper>
      <div className="w-full md:h-20 h-14 bg-[#003566] p-5">
        <h1 className="text-4xl font-bold text-white">Contact Us</h1>
      </div>
      <div className="w-full h-[100vh] bg-[#E9EDC9] flex flex-col md:flex-row">
        <div className="w-full h-[270px] md:h-full bg-[#EDF6F9]">
          <div className="w-[80%] h-[300px]  m-10 flex flex-col gap-8">
            <div className="flex gap-4">
              {/* Message Logo */}
              <div className="md:w-[15%] w-[10%] h-12 bg-[#FFFFFF] rounded-lg border-2 border-gray-300 p-2">
                <RiMessage2Line className="w-full h-full" />
              </div>
              <div className="flex flex-col">
                <p>Send us and email</p>
                <p>Info@myysports.com</p>
              </div>
            </div>
            <div>
              <div className="flex gap-4">
                {/* Message Logo */}
                <div className="w-[12%] md:w-[25%] h-12 bg-[#FFFFFF] rounded-lg border-2 border-gray-300">
                  <IoLocationOutline className="w-full h-full p-2" />
                </div>
                <div className="flex flex-col">
                  <p>Location</p>
                  <p>A-66, FIEE complex, Okhla Phase II, New Delhi-110020, Delhi NCR, India </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="w-full h-full bg-[#FFFFFF] p-5">
          <form onSubmit={handleSubmit} className="space-y-6">
            <div>
              <label htmlFor="fullName" className="block text-sm font-medium text-gray-700">
                Full Name
              </label>
              <input
                type="text"
                id="fullName"
                name="fullName"
                value={formData.fullName}
                onChange={handleChange}
                className="mt-1 block w-full px-4 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                required
              />
            </div>
            <div>
              <label htmlFor="contactNumber" className="block text-sm font-medium text-gray-700">
                Contact Number
              </label>
              <input
                type="tel"
                id="contactNumber"
                name="contactNumber"
                value={formData.contactNumber}
                onChange={handleChange}
                className="mt-1 block w-full px-4 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                required
              />
            </div>
            <div>
              <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                Email
              </label>
              <input
                type="email"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                className="mt-1 block w-full px-4 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                required
              />
            </div>
            <div>
              <label htmlFor="emailSubject" className="block text-sm font-medium text-gray-700">
                Email Subject
              </label>
              <input
                type="text"
                id="emailSubject"
                name="emailSubject"
                value={formData.emailSubject}
                onChange={handleChange}
                className="mt-1 block w-full px-4 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
              />
            </div>
            <div>
              <label htmlFor="message" className="block text-sm font-medium text-gray-700">
                Your Message
              </label>
              <textarea
                id="message"
                name="message"
                value={formData.message}
                onChange={handleChange}
                rows="4"
                className="mt-1 block w-full px-4 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                required
              ></textarea>
            </div>
            <div>
              <button
                type="submit"
                className="w-full bg-blue-500 text-white py-2 px-4 rounded-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
              >
                Send Message
              </button>
            </div>
          </form>
        </div>
      </div>
    </MainWidthWrapper>
  );
};
export default SupportPage;
