import React, { useState, useEffect } from 'react';
import { Box } from '@mui/system';
import BatIcon from './ui/BatIcon';
import AwardIcon from './ui/AwardIcon';
import UserIcon from './ui/UserIcon';
import gullyLogo from '../assets/NewLogo.svg';
import { useLocation } from 'react-router-dom';

const MainWidthWrapper = ({ children }) => {
  return (
    <Box
      sx={{
        fontFamily: 'Arial, sans-serif',
        mx: 'auto',
        width: { md: '55%', sm: '100%' },
      }}
    >
      {children}
    </Box>
  );
};
const Navbar = ({ onBatClick, onAvatarClick, onAwardClick, onTeamClick, onlogoClick }) => {
  const location = useLocation(); // Extract param from the URL
  const [selectedPage, setSelectedPage] = useState(() => {
    return location.pathname; // Use paramURL if available, else 0
  });
  useEffect(() => {
    setSelectedPage(location.pathname);
  }, [location]);
  return (
    <MainWidthWrapper>
      <div className="h-[50px] flex justify-between p-[10px] bg-white">
        <div className="w-[190px] flex items-center" onClick={onlogoClick}>
          <img src={gullyLogo} alt="" className="w-[28px]" />
          <p className="text-[18px] leading-[26px] font-poppins text-LogoBlue font-extrabold tracking-[0.02em] ml-[6px]">
            GULLY BALL
          </p>
        </div>

        <div className="flex gap-[5px] md:gap-[10px] items-center h-full cursor-pointer">
          <div
            className={`${selectedPage === '/' ? 'bg-LogoRed' : ''} rounded-md p-[5px] py-1 h-full`}
            onClick={() => {
              onBatClick();
            }}
          >
            <BatIcon fillColor={selectedPage === '/' ? '#FFCB05' : '#0d1b2a'} />
          </div>
          <div
            className={`${selectedPage === '/tournaments' ? 'bg-LogoRed' : ''} rounded-md p-[5px] py-1 h-full`}
            onClick={() => {
              onAwardClick();
            }}
          >
            <AwardIcon fillColor={selectedPage === '/tournaments' ? '#FFCB05' : '#0d1b2a'} />
          </div>
          <div
            className={`${selectedPage === '/user-profile' ? 'bg-LogoRed' : ''} rounded-md p-[5px] py-1 h-full`}
            onClick={() => {
              onAvatarClick();
            }}
          >
            <UserIcon
              fillColor={selectedPage === '/user-profile' ? '#FFCB05' : '#0d1b2a'}
              backColor={selectedPage === '/user-profile' ? '#BA3C3D' : '#F1F5F9'}
            />
          </div>
        </div>
      </div>
      <div className="w-full h-[2px] bg-[#F2F4F5]"></div>
    </MainWidthWrapper>
  );
};

export default Navbar;
