import React, { useRef, useEffect, useState } from 'react';
import AddButton from '../ui/AddButton';
import ScanIcon from '../../assets/scanIcon.svg';
import QrScanner from 'qr-scanner';

const PlayersDetails = ({ inputs, setInputs }) => {
  const [isScannerOpen, setIsScannerOpen] = useState(false); // State to manage scanner visibility
  const [errorMessage, setErrorMessage] = useState('');
  const videoRef = useRef(null); // Reference to the video element
  const [qrScanner, setQrScanner] = useState(null); // Store the QrScanner instance
  const boxRef = useRef(null);
  const [isInputFocused, setIsInputFocused] = useState(false);

  const [newPlayer, setNewPlayer] = useState();
  useEffect(() => {
    if (isScannerOpen && videoRef.current) {
      const scanner = new QrScanner(
        videoRef.current,
        (result) => {
          try {
            const parsedResult = JSON.parse(result.data); // Parse the incoming data
            // const userData = JSON.parse(parsedResult.data); // Extract and parse user data
            setNewPlayer(parsedResult); // Update the state with the parsed user data
            handleScanSuccess(); // Close the scanner on success
          } catch (error) {
            console.error('Error parsing scanned data:', error);
          }
        },
        {
          returnDetailedScanResult: true, // Optional options
        }
      );
      setQrScanner(scanner); // Store the instance in state
      scanner.start();

      return () => {
        scanner.stop(); // Stop the scanner when component unmounts or scanner closes
      };
    }
  }, [isScannerOpen]);
  const handleNameChange = (e, index) => {
    setIsInputFocused(true);
    const inputValue = e.target.value;
    const lettersOnly = inputValue.replace(/[^a-zA-Z]/g, ' '); // Regex to allow only letters
    const newPlayerData = [...inputs.playersData];
    newPlayerData[index].playerName = lettersOnly;
    setInputs((prevInput) => ({ ...prevInput, playersData: newPlayerData }));
  };
  const handleContactChange = (e, index) => {
    setIsInputFocused(true);
    const inputValue = e.target.value;

    const newPlayerData = [...inputs.playersData];

    // Check if input starts with +91 and has 3 or fewer characters, remove it
    if (inputValue.startsWith('+91') && inputValue.length <= 4) {
      newPlayerData[index].playerContact = ''; // Clear contact field
    }
    // Check if the input is numeric after removing the +91, and handle backspacing numbers
    else if (/^\+91\d*$/.test(inputValue) || /^\d+$/.test(inputValue)) {
      newPlayerData[index].playerContact = inputValue.startsWith('+91') ? inputValue : `+91 ${inputValue}`;
    }
    // Handle non-numeric input without appending +91
    else {
      newPlayerData[index].playerContact = inputValue;
    }

    // Update the state with the new player data
    setInputs((prevInputs) => ({ ...prevInputs, playersData: newPlayerData }));
  };

  const handleInputCount = () => {
    setInputs((prevInputs) => ({
      ...prevInputs,
      playersData: [...prevInputs.playersData, { playerName: '', playerContact: '' }],
    }));
  };

  useEffect(() => {
    if (!isInputFocused && boxRef.current) {
      boxRef.current.scrollTop = boxRef.current.scrollHeight; // Scroll to the bottom
    }
  }, [inputs.playersData]); // Trigger scroll when inputCount changes

  // useEffect(() => {
  //   if (newPlayer) {
  //     setInputs((prevInputs) => ({
  //       ...prevInputs,
  //       playersData: [...prevInputs.playersData, newPlayer.playerName],
  //     }));
  //   }
  // }, [newPlayer]);

  const handleScanSuccess = () => {
    if (qrScanner) {
      qrScanner.stop(); // Stop the camera after scan
    }
    setIsScannerOpen(false); // Close the scanner UI
  };

  useEffect(() => {
    if (newPlayer) {
      // Find the next empty input field where both name and contact are empty
      let emptyIndex = inputs.playersData.findIndex((player) => player.playerName === '' && player.playerContact === '');

      const newPlayerData = [...inputs.playersData];

      // Only update if an empty field is found
      if (emptyIndex !== -1) {
        newPlayerData[emptyIndex] = {
          playerName: newPlayer.playerName || '', // Assign player name
          playerContact: newPlayer.email || '', // Assign player contact if available
          // email: newPlayer.email || '', // Assign email if available
        };

        // Update state with new player data
        setInputs((prevInputs) => ({
          ...prevInputs,
          playersData: newPlayerData,
        }));
      } else {
        // No empty field found, add the new player to the array
        setInputs((prevInputs) => ({
          ...prevInputs,
          playersData: [
            ...prevInputs.playersData,
            {
              playerName: newPlayer.playerName || '', // Assign player name
              playerContact: newPlayer.email || '', // Assign player contact if available
              // email: newPlayer.email || '', // Assign email if available
            },
          ],
        }));
      }

      // Clear newPlayer after adding
      setNewPlayer(null);
    }
  }, [newPlayer, inputs.playersData, setInputs]);

  return (
    <>
      {isScannerOpen && (
        <div className="fixed inset-0 w-screen h-screen bg-black bg-opacity-90 flex justify-center items-center z-50">
          {/* Header */}
          <div className="absolute top-5 flex items-center justify-between w-full px-4">
            {/* Back button */}
            <button onClick={() => setIsScannerOpen(false)} className="text-white text-2xl">
              ←
            </button>
            <h2 className="text-white text-lg font-semibold">ADD A PLAYER</h2>

            {/* Close button */}
            <button
              onClick={() => setIsScannerOpen(false)}
              className="bg-white text-black py-1 px-3 rounded-full border-none cursor-pointer z-50"
            >
              ✕
            </button>
          </div>

          {/* Video element for scanning */}
          <div className="relative w-full h-full">
            <video ref={videoRef} className="w-full h-full object-cover" />

            {/* Overlay for the rest of the screen except the scanning area */}
            <div className="absolute inset-0 z-10 pointer-events-none">
              {/* Top part of the overlay */}
              <div className="absolute top-0 left-0 w-full h-[calc(50%-150px)] bg-black bg-opacity-70"></div>

              {/* Bottom part of the overlay */}
              <div className="absolute bottom-0 left-0 w-full h-[calc(50%-150px)] bg-black bg-opacity-70"></div>

              {/* Left part of the overlay */}
              <div className="absolute top-[calc(50%-150px)] left-0 w-[calc(50%-150px)] h-[300px] bg-black bg-opacity-70"></div>

              {/* Right part of the overlay */}
              <div className="absolute top-[calc(50%-150px)] right-0 w-[calc(50%-150px)] h-[300px] bg-black bg-opacity-70"></div>

              {/* Blue guide lines for the scanning area */}
              <div className="absolute top-[calc(50%-150px)] left-[calc(50%-150px)] w-[300px] h-[300px] z-20">
                {/* Top-left corner */}
                <div className="absolute top-0 left-0 w-8 h-8 border-t-[4px] border-l-[4px] border-[#4A90E2]"></div>
                {/* Top-right corner */}
                <div className="absolute top-0 right-0 w-8 h-8 border-t-[4px] border-r-[4px] border-[#4A90E2]"></div>
                {/* Bottom-left corner */}
                <div className="absolute bottom-0 left-0 w-8 h-8 border-b-[4px] border-l-[4px] border-[#4A90E2]"></div>
                {/* Bottom-right corner */}
                <div className="absolute bottom-0 right-0 w-8 h-8 border-b-[4px] border-r-[4px] border-[#4A90E2]"></div>
              </div>
            </div>
          </div>

          {/* Flashlight button */}
          <button className="absolute top-[calc(50%+160px)] left-1/2 transform -translate-x-1/2 bg-[#2c3e50] text-white p-3 rounded-md">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="w-6 h-6">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>

          {/* Instruction text */}
          <p className="absolute bottom-16 left-1/2 transform -translate-x-1/2 text-center text-blue-500 text-sm bg-blue-100 rounded-full px-4 py-2">
            The QR Code will be automatically detected when you position it between the guide lines
          </p>
        </div>
      )}
      <div className="w-full h-[300px] flex flex-col gap-1">
        <div className="w-full h-[24px] flex items-center justify-between">
          <p className="font-roboto text-[12px] font-semibold leading-[20px] text-left">ADD OR INVITE PLAYERS</p>
          <div className="w-[130px] h-[24px] flex items-center justify-end">
            <p className="font-roboto text-[11px] font-normal leading-[20px] text-left">SCAN QR</p>
            <img
              src={ScanIcon}
              alt="Scanner Image"
              className="w-[70px] h-[29px] cursor-pointer"
              onClick={() => setIsScannerOpen(true)}
            />
          </div>
        </div>
        <div ref={boxRef} className="w-full h-[300px] flex flex-col gap-2 overflow-y-auto custom-scrollbar mt-2">
          {inputs.playersData.map((playerData, index) => (
            <div className="w-full h-full flex flex-col gap-3 justify-center mt-2" key={index}>
              <div className="w-full h-[30px] flex items-center gap-3">
                <div className="w-[7%] md:w-[3%] h-[24px] bg-[#495ECA] text-white p-2 rounded-lg flex items-center justify-center">
                  {index + 1}
                </div>

                {/* Player name input data */}
                <input
                  type="text"
                  placeholder="Player Name"
                  value={playerData.playerName || ''} // Value from playersData array
                  className="w-[100%] h-full border border-gray-300 rounded-lg py-2 px-1.5 bg-transparent border-gradien focus:outline-none"
                  style={{
                    borderImageSource:
                      'linear-gradient(0deg, #FFFFFF, #FFFFFF), linear-gradient(0deg, rgba(10, 10, 10, 0.1), rgba(10, 10, 10, 0.1))',
                  }}
                  onChange={(e) => handleNameChange(e, index)}
                />
                {errorMessage && <p className="text-red-500 text-xs mt-1">{errorMessage}</p>}
              </div>

              <div className="w-[100%]">
                {/* {/* player contact (Email/Mobile) input data  */}
                <input
                  type="text"
                  placeholder="Enter player mobile number or email"
                  value={playerData.playerContact || ''} // Update to playerContact instead of email
                  className="w-[100%] h-[40px] border border-gray-300 rounded-lg py-2 px-1.5 bg-transparent border-gradient focus:outline-none"
                  style={{
                    borderImageSource:
                      'linear-gradient(0deg, #FFFFFF, #FFFFFF), linear-gradient(0deg, rgba(10, 10, 10, 0.1), rgba(10, 10, 10, 0.1))',
                  }}
                  onChange={(e) => handleContactChange(e, index)} // Passing the index for correct player data
                />
              </div>
              <div className="block w-full h-[1px] border-t-2  border-[#0A0A0A33]"></div>
            </div>
          ))}
        </div>
      </div>
      <div className="w-full flex items-center gap-3 cursor-pointer" onClick={handleInputCount}>
        <AddButton />
        <p className="text-[#B59000] font-roboto text-[11px] font-normal leading-[20px] text-left">ADD MORE PLAYERS</p>
      </div>
    </>
  );
};
export default PlayersDetails;
