import React from 'react';
import '../css/live.css';

const LiveButton = () => {
  return (
    <div
      className="live-button"
      style={{
        position: 'absolute',
        zIndex: '10',
        width: '37px',
        height: '11px',
        top: '-5px',
        left: '45%',
        padding: '6px 6px 4px 6px',
        gap: '4px',
        borderRadius: '2px 2px 2px 2px',
        background: '#F74646',
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <span
        style={{
          display: 'block',
          width: '4px',
          height: '4px',
          backgroundColor: 'white',
          borderRadius: '50%',
          marginBottom: '2px',
        }}
      ></span>
      <span
        style={{
          fontSize: '7px',
          fontWeight: 700,
          lineHeight: '6.58px',
          textAlign: 'center',
          color: '#FFFFFF',
        }}
      >
        LIVE
      </span>
    </div>
  );
};

export default LiveButton;
