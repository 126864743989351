import React from 'react';
import Box from '@mui/material/Box';
import { Table, TableBody, TableCell, TableContainer, TableRow, Paper } from '@mui/material';
import Typography from '@mui/material/Typography';
import { useSelector } from 'react-redux';
import { makeStyles } from '@mui/styles';
import Team from './../assets/Team.jpg';

const useStyles = makeStyles((theme) => ({
  teamImage: {
    height: 28,
    width: 28,
    borderRadius: '50%',
    marginRight: 5,
    background: `url(${Team}) no-repeat center / cover`,
    transform: 'translateY(-3px)',
  },
}));

const CricketCard = ({ inning }) => {
  const classes = useStyles();
  const matchData = useSelector((state) => state.match);
  const isLive = matchData?.isLive;
  const teamScore = matchData?.currScore?.teamScore || {};
  const batsManDetail = inning?.batsManDetail || [];
  const bowlerDetail = inning?.bowlerDetail || [];

  const Team = inning?.teamName ? `${inning.teamName}` : null;
  const Runs = teamScore?.[inning?.teamName]?.inningScore;
  const overDetails = teamScore?.[inning?.teamName]?.inningOver;

  const batters = batsManDetail.map((batter) => batter.batsmanName);
  const bowlers = bowlerDetail.map((bowler) => bowler.bowlerName);

  // Extract onPitch details for the current inning team
  const onPitch = teamScore?.[inning?.teamName]?.onPitch || {};
  const { striker, nonStriker, bowler } = onPitch;

  // Extract limited overs for the current inning
  const over = matchData?.data?.[0]?.info?.[0]?.limitedOver;

  const nob = matchData?.data?.[0]?.info?.[0]?.numOfBallsPerOver;

  const formatOvers = (balls) => {
    const overs = Math.floor(balls / nob);
    const remainingBalls = balls % nob;
    return `${overs}.${remainingBalls}`;
  };

  const formatTeamName = (team) => {
    if (team) {
      return team.length > 12 ? `${team.substring(0, 12)}...` : team.toUpperCase();
    }
    return 'TEAM';
  };

  return (
    <Box
      sx={{
        padding: '10px 8px 4px 8px',
        gap: '6px',
        borderRadius: '10px',
        border: '1px solid #C8CDF9',
        background: `linear-gradient(180deg, rgba(235, 234, 252, 0.52) 0%, rgba(235, 234, 252, 0) 100%)`,
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
        margin: '10px 16px',
      }}
    >
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Box sx={{ display: 'flex', gap: '0px' }}>
          <Box className={classes.teamImage} />
          <Typography
            sx={{
              fontWeight: 600,
              fontSize: '12px',
              textTransform: 'uppercase',
              lineHeight: '20px',
              textAlign: 'left',
              color: '#000000',
            }}
          >
            {formatTeamName(Team)}
          </Typography>
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Typography
            sx={{
              fontWeight: 700,
              fontSize: '14px',
              lineHeight: '16px',
              textAlign: 'right',
              marginRight: '10px',
            }}
          >
            {Runs}
          </Typography>
          <Typography sx={{ fontSize: '12px', fontWeight: 500, lineHeight: '16px', textAlign: 'right' }}>
            {overDetails}/{over} ov
          </Typography>
        </Box>
      </Box>
      {isLive && (
        <TableContainer component={Paper}>
          <Table sx={{ padding: '1px 0px 1px 0px', gap: '32px' }}>
            <TableBody>
              {striker && onPitch.strikerScore && (
                <TableRow>
                  <TableCell sx={{ padding: '2px', height: '24px' }}>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                      <Box sx={{ display: 'flex' }}>
                        <Typography
                          sx={{
                            fontWeight: 500,
                            fontSize: '13px',
                            lineHeight: '16px',
                            textAlign: 'left',
                            color: '#000000',
                          }}
                        >
                          {striker}
                        </Typography>
                      </Box>
                      <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <Typography
                          sx={{
                            fontWeight: 500,
                            fontSize: '13px',
                            lineHeight: '16px',
                            textAlign: 'right',
                            color: '#000000',
                          }}
                        >
                          {onPitch.strikerScore.runs}
                        </Typography>
                        <Typography
                          style={{
                            gap: '0px',
                            fontSize: '11px',
                            fontWeight: 500,
                            lineHeight: '16px',
                            textAlign: 'right',
                            color: '#0A0A0AB2',
                          }}
                        >
                          ({onPitch.strikerScore.balls})
                        </Typography>
                      </Box>
                    </Box>
                  </TableCell>
                  {bowler && onPitch.bowlerScore && (
                    <TableCell sx={{ padding: '2px', height: '24px' }}>
                      <Box sx={{ display: 'flex', justifyContent: 'space-between', marginLeft: '10px' }}>
                        <Box sx={{ display: 'flex' }}>
                          <Typography
                            sx={{
                              fontWeight: 500,
                              fontSize: '13px',
                              lineHeight: '16px',
                              textAlign: 'left',
                              color: '#000000',
                            }}
                          >
                            {bowler}
                          </Typography>
                        </Box>
                        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                          <Typography
                            sx={{
                              fontWeight: 500,
                              fontSize: '13px',
                              lineHeight: '16px',
                              textAlign: 'right',
                              color: '#000000',
                            }}
                          >
                            {onPitch.bowlerScore.runsGiven}
                          </Typography>
                          <Typography
                            sx={{
                              gap: '0px',
                              fontSize: '11px',
                              fontWeight: 500,
                              lineHeight: '16px',
                              textAlign: 'right',
                              color: '#0A0A0AB2',
                            }}
                          >
                            ({formatOvers(onPitch.bowlerScore.ballsDelivered)})
                          </Typography>
                        </Box>
                      </Box>
                    </TableCell>
                  )}
                </TableRow>
              )}
              {nonStriker && onPitch.nonStrikerScore && (
                <TableRow>
                  <TableCell sx={{ padding: '2px', height: '24px' }}>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                      <Box sx={{ display: 'flex' }}>
                        <Typography
                          sx={{
                            fontWeight: 500,
                            fontSize: '13px',
                            lineHeight: '16px',
                            textAlign: 'left',
                            color: '#000000',
                          }}
                        >
                          {nonStriker}
                        </Typography>
                      </Box>
                      <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <Typography
                          sx={{
                            fontWeight: 500,
                            fontSize: '13px',
                            lineHeight: '16px',
                            textAlign: 'right',
                            color: '#000000',
                          }}
                        >
                          {onPitch.nonStrikerScore.runs}
                        </Typography>
                        <Typography
                          sx={{
                            gap: '0px',
                            fontSize: '11px',
                            fontWeight: 500,
                            lineHeight: '16px',
                            textAlign: 'right',
                            color: '#0A0A0AB2',
                          }}
                        >
                          ({onPitch.nonStrikerScore.balls})
                        </Typography>
                      </Box>
                    </Box>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </Box>
  );
};

export default CricketCard;
