import axios from 'axios';
import {
  setLeagueTable,
  setUngroupedteams,
  setGroupedteamsLeagueTable,
  setTournamentMatches,
  toggleTournamentLoading,
  setTournamentError,
  setTotalTournaments,
} from './tournament.slice';
import api from '../../api/api';

export const fetchPointsTable = (tournamentId) => {
  return async (dispatch) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}api/v1/cricket/tournament/leagueTable/${tournamentId}?user=65574129354096865f1ebf70`
      );
      const { ungroupedTeamsLeagueTable } = response.data.data;
      if (ungroupedTeamsLeagueTable && ungroupedTeamsLeagueTable?.length > 0) {
        dispatch(setLeagueTable(ungroupedTeamsLeagueTable));
      }
      const { ungroupedTeams } = response.data.data;
      ungroupedTeams && dispatch(setUngroupedteams(ungroupedTeams));
      const groupedTeams = response?.data?.data?.groupedTeams;
      if (groupedTeams && Object.keys(groupedTeams).length > 0) {
        dispatch(setGroupedteamsLeagueTable(groupedTeams));
      }
    } catch (error) {
      dispatch(setTournamentError(error?.response?.data || error?.message || error));
    }
  };
};

export const fetchTournamentMatches = (tournamentId) => {
  return async (dispatch) => {
    try {
      dispatch(toggleTournamentLoading());

      const url = `${process.env.REACT_APP_API_BASE_URL}api/v1/cricket/tournament/matches?tournamentId=${tournamentId}&page=1&pageSize=12`;

      const response = await api.get(url);

      dispatch(setTournamentMatches(response.data));
      dispatch(toggleTournamentLoading());
    } catch (error) {
      console.error('Error fetching all matches:', error);
      dispatch(setTournamentError(error?.response?.data || error?.message || error));
    }
  };
};

export const fetchAllTournaments = (currentPage, userObjId = null, sortBy = 'name', sortOrder = 'asc') => {
  return async (dispatch) => {
    try {
      let url = `${process.env.REACT_APP_API_BASE_URL}api/v1/cricket/tournaments?page=${currentPage}&pageSize=15&sortOrder=${sortOrder}`;
      if (sortBy) {
        url += `&sortBy=${sortBy}`;
      }
      if (userObjId) {
        url += `&userObjId=${userObjId}`;
      }
      const response = await api.get(url);
      dispatch(setTotalTournaments(response.data.data));
    } catch (error) {
      dispatch(setTournamentError(error?.response?.data || error?.message || error));
    }
  };
};
