import api from '../../api/api';
import { setIsTeamCreated, setIsPlayerAdded, setError } from './team.slice';

export const CreateTeam = (reqPayload) => {
  return async (dispatch) => {
    try {
      const response = await api.post(`${process.env.REACT_APP_API_BASE_URL}api/v1/cricket/team/create/`, reqPayload);
      setIsTeamCreated(true);
    } catch (error) {
      dispatch(setError(error?.response?.message || error?.message));
    }
  };
};

export const AddPlayer = (reqPayload) => {
  return async (dispatch) => {
    try {
      const response = await api.post(`${process.env.REACT_APP_API_BASE_URL}api/v0/cricket/players/bulkAdd/`, reqPayload);
      dispatch(setIsPlayerAdded());
    } catch (error) {
      dispatch(setError(error?.response?.message || error?.message));
    }
  };
};
