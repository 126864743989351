import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import './css/livewinprob.css';
import { Typography } from '@mui/material';

const LiveWinProbabilityPredictor = () => {
  const { liveProbability, data } = useSelector((state) => state.match);
  const matchData = useSelector((state) => state.match);
  const [winProbability, setWinProbability] = useState(50);

  useEffect(() => {
    if (liveProbability && liveProbability.winProb && liveProbability.winProb.prob) {
      const probability = parseFloat(liveProbability.winProb.prob) * 100;
      if (!isNaN(probability) && probability >= 0 && probability <= 100) {
        setWinProbability(probability);
      } else {
        console.warn('Invalid probability value received:', probability);
      }
    } else {
      console.warn('No valid probability found in liveProbability.winProb');
    }
  }, [liveProbability]);

  const calculateLineColors = () => {
    const leftColor = getProbabilityColor(TeamA);
    const rightColor = getProbabilityColor(TeamB);
    return [`${leftColor} ${winProbability}%`, `${rightColor} ${100 - winProbability}%`];
  };

  const getProbabilityColor = (team) => {
    return team === battingTeam ? '#cc4d4d' : '#7b62c6';
  };

  const battingTeam = liveProbability?.winProb?.battingTeam;
  const TeamA = matchData?.data?.[0]?.info?.[0]?.teams?.[0]?.teamName;
  const TeamB = matchData?.data?.[0]?.info?.[0]?.teams?.[1]?.teamName;

  let teamAName, teamBName;
  if (battingTeam === TeamA) {
    teamAName = TeamA;
    teamBName = TeamB;
  } else {
    teamAName = TeamB;
    teamBName = TeamA;
  }
  const otherTeam = battingTeam === TeamA ? TeamB : TeamA;

  return (
    <div className="live-win-probability-predictor">
      <Typography style={{ marginBottom: '30px', fontSize: '12px', marginTop: '5px' }}>LIVE WIN PROBABILITY</Typography>
      <div className="win-probability-design">
        <div className="line-container">
          <div
            className="line"
            style={{
              background: `linear-gradient(to right, ${calculateLineColors()[0]}, ${calculateLineColors()[1]})`,
            }}
          />
        </div>
        <div className="teamA">
          <p>{teamAName}</p>
          <p style={{ color: getProbabilityColor(TeamA) }}>
            {winProbability !== null ? `${winProbability.toFixed(0)}%` : 'Calculating...'}
          </p>
        </div>
        <div className="teamB">
          <p>{teamBName}</p>
          <p style={{ color: getProbabilityColor(TeamB) }}>
            {winProbability !== null ? `${(100 - winProbability).toFixed(0)}%` : 'Calculating...'}
          </p>
        </div>
      </div>
    </div>
  );
};

export default LiveWinProbabilityPredictor;
