import React, { useRef, useState, useEffect, useCallback } from 'react';
import { Box, List, ListItem, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import MainWidthWrapper from './MainWidthWrapper';
import { fetchAllTournaments } from '../redux/tournament/tournament.action';
import { clearTournaments } from './../redux/tournament/tournament.slice';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import IconToggle from './ui/ToggleIcon';
import Icon from './../assets/tournament.png';
import NoDataComponent from './NoDataComponent';
import { toggleAuthWallOff, toggleAuthWallOn } from '../redux/auth/auth.slice';

const TournamentList = () => {
  const dispatch = useDispatch();
  const Alltournamentlist = useSelector((state) => state.tournament.Alltournamentlist, shallowEqual);
  const { user, isAuthenticated, loading } = useSelector((state) => state.auth);
  const { totalTournaments } = useSelector((state) => state.tournament);
  const { userObjId } = user;
  const [currentPage, setPage] = useState(1);
  const [sortBy, setSortBy] = useState('name');
  const [sortOrder, setSortOrder] = useState('asc');

  // Fetch tournaments on load or when any of the dependencies change
  useEffect(() => {
    if (!loading) {
      console.log(userObjId, loading, 'hufuh');
      if (userObjId && isAuthenticated) {
        dispatch(fetchAllTournaments(currentPage, userObjId, sortBy, sortOrder));
      } else {
        dispatch(fetchAllTournaments(currentPage, null, sortBy, sortOrder));
      }
    }
  }, [dispatch, currentPage, sortBy, sortOrder, userObjId, loading]);

  // Resets tournaments and page whenever sorting changes
  const handleSortChange = () => {
    dispatch(clearTournaments());
    setPage(1);
  };

  // Trigger sort change whenever sortBy or sortOrder updates
  useEffect(() => {
    handleSortChange();
  }, [sortBy, sortOrder]);

  const tournaments = Alltournamentlist || [];

  const handleInfinityScroll = useCallback(() => {
    if (window.innerHeight + document.documentElement.scrollTop + 1 >= document.documentElement.scrollHeight) {
      setPage((prev) => prev + 1);
    }
  }, []);

  useEffect(() => {
    window.addEventListener('scroll', handleInfinityScroll);
    return () => window.removeEventListener('scroll', handleInfinityScroll);
  }, [handleInfinityScroll]);

  return (
    <>
      <MainWidthWrapper>
        <Box sx={{ px: 0.5, py: 1, position: 'relative' }}>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              width: '100%',
              marginBottom: '6px',
              marginLeft: '4px',
              marginTop: '0.75rem',
            }}
          >
            <span
              style={{
                marginRight: '8px',
                marginLeft: '4px',
                fontSize: '14px',
                fontWeight: 600,
                lineHeight: '16.41px',
                textAlign: 'left',
              }}
            >
              TOURNAMENTS
            </span>
            <hr style={{ flex: 1, border: '0.5px solid black', marginRight: '4px' }} />
          </div>
          <Box
            sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginRight: '4px', marginLeft: '6px' }}
          >
            <Typography
              sx={{
                height: '14px',
                gap: ' 0px',
                fontSize: '12px',
                fontWeight: '500',
                lineHeight: '14px',
                letterSpacing: '0.10000000149011612px',
                color: '#0A0A0A',
              }}
            >
              {`Showing ${totalTournaments} tournaments`}
            </Typography>
            <IconToggle sortBy={sortBy} setSortBy={setSortBy} sortOrder={sortOrder} setSortOrder={setSortOrder} />
          </Box>
          {tournaments.length > 0 ? (
            <List sx={{ pt: 0 }}>
              {tournaments.map((tournament, index) => (
                <ListItem
                  key={index}
                  component={Link}
                  to={`/tournament-profile/${tournament?._id}`}
                  style={{ textDecoration: 'none', color: 'inherit' }}
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    paddingLeft: 0,
                    borderBottom: '1px solid #ccc',
                  }}
                >
                  <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                    <img
                      src={Icon}
                      alt={`${tournament.name} logo`}
                      style={{
                        width: 40,
                        height: 40,
                        borderRadius: '50%',
                        objectFit: 'cover',
                        marginRight: 12,
                        marginLeft: 10,
                      }}
                    />
                    <Box sx={{ mt: 0 }}>
                      {/* <Typography
                        sx={{
                          width: '100%',
                          height: '16px',
                          gap: '0px',
                          opacity: 1,
                          transform: 'rotate(-0deg)',
                          fontSize: '14px',
                          fontWeight: 500,
                          lineHeight: '16.41px',
                          textAlign: 'left',
                          color: '#0A0A0A',
                          whiteSpace: 'nowrap', // Prevents text from wrapping
                          overflow: 'hidden', // Ensures overflow is hidden
                          textOverflow: 'ellipsis', // Adds ellipsis for overflow
                          maxWidth: '25ch', // Limits the width to 25 characters approximately
                        }}
                      >
                        @{tournament.name}
                      </Typography> */}
                      <Typography
                        sx={{
                          width: '100%',
                          height: '16px',
                          gap: '0px',
                          opacity: 1,
                          transform: 'rotate(-0deg)',
                          fontSize: '14px',
                          fontWeight: 400,
                          lineHeight: '16.41px',
                          textAlign: 'left',
                          color: '#5E5F5D',
                          whiteSpace: 'nowrap', // Prevents text from wrapping
                          overflow: 'hidden', // Ensures overflow is hidden
                          textOverflow: 'ellipsis', // Adds ellipsis for overflow
                          maxWidth: '25ch', // Limits the width to 25 characters approximately
                        }}
                      >
                        {tournament.name}
                      </Typography>
                    </Box>
                  </Box>
                </ListItem>
              ))}
            </List>
          ) : (
            <NoDataComponent label={'NO TOURNAMENTS AVAILABLE.'} />
          )}
        </Box>
      </MainWidthWrapper>
    </>
  );
};

export default TournamentList;
