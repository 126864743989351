import React, { useEffect, useState, useMemo, lazy, Suspense } from 'react';
import { Navigate, Route, BrowserRouter as Router, Routes, useLocation } from 'react-router-dom';
import ReactGA from 'react-ga4';
import ErrorBoundary from './components/error/ErrorBoundry';
import NotFoundPage from './pages/default/NotFoundPage';
import MatchList from './pages/cricketMatch/matchList';
import MatchContainer from './pages/cricketMatch/matchContainer';
import Cookies from 'js-cookie';
import { setIsAuthenticated } from './redux/auth/auth.slice';
import { useSelector, useDispatch } from 'react-redux';
import { verifyAccessToken } from './redux/auth/auth.action';
import PrivateRoute from './components/Dashboard/PrivateRoute';
import TournamentList from './components/tournamentlist';
import UserProfile from './components/userProfile';
import Layout from './components/Layout';
import SupportPage from './pages/policies/Support';
import AuthWrapper from './components/AuthWrapper';
// import Cookiespolicy from './pages/policies/Cookies';

const CreationForm = lazy(() => import('./components/Team-Creation/CreationForm'));
const MatchJsonViewPage = lazy(() => import('./pages/cricketMatch/matchListPage/match.jsonView'));
const MatchesTablePage = lazy(() => import('./pages/cricketMatch/matchListPage/matches.page'));
const ProfileContainer = lazy(() => import('./pages/ProfilePage/ProfileContainer'));
const TermsAndConditions = lazy(() => import('./pages/policies/termsAndConditions'));
const PrivacyPolicy = lazy(() => import('./pages/policies/privacyPolicy'));
const Cookiespolicy = lazy(() => import('./pages/policies/Cookies'));
const DeleteAccount = lazy(() => import('./pages/policies/deleteAccount'));
const DeleteData = lazy(() => import('./pages/policies/deleteData'));
const AboutUsPage = lazy(() => import('./pages/policies/AboutUs'));
const GullyBallInfo = lazy(() => import('./pages/policies/GullyBallInfo'));
const TeamProfilePage = lazy(() => import('./components/Team-Profile/TeamProfile'));
const PlayerProfilepage = lazy(() => import('./components/Player-Profile/PlayerProfile'));
const AuthWall = lazy(() => import('./components/auth/AuthWall'));
const DashBoardPage = lazy(() => import('./pages/DashboardPage/DashboardPage'));
const LoginPage = lazy(() => import('./components/Dashboard/Login'));

const App = () => {
  const dispatch = useDispatch();
  const matchData = useSelector((state) => state.match);
  const { isAuthenticated, user } = useSelector((state) => state.auth);
  const isdummymatch = matchData?.isDummy;

  const GA_TRACKER_ID = process.env.REACT_APP_GA_TRACKER_ID;

  const TrackPageViews = () => {
    const location = useLocation();

    useEffect(() => {
      if (!isdummymatch) {
        ReactGA.initialize(GA_TRACKER_ID);
        ReactGA.send({
          hitType: 'pageview',
          page: location.pathname + location.search,
        });
      }
    }, [location]);
  };

  useEffect(() => {
    const accessToken = Cookies.get('accessToken');
    if (accessToken) {
      dispatch(verifyAccessToken());
    } else {
      dispatch(setIsAuthenticated(false));
    }
  }, [dispatch]);

  return (
    <Router>
      <ErrorBoundary>
        <Suspense fallback={<div>Loading...</div>}>
          <TrackPageViews />
          <Routes>
            <Route exact path="/" element={<Layout />} />
            <Route
              exact
              path="/match/:matchId"
              element={
                <ErrorBoundary>
                  <MatchContainer />
                </ErrorBoundary>
              }
            />

            <Route exact path="/tournament-profile/:tournamentId" element={<ProfileContainer />} />
            <Route exact path="/team/:teamObjectId" element={<TeamProfilePage />} />
            <Route exact path="/player/:userName" element={<PlayerProfilepage />} />
            <Route exact path="/team-creation" element={<Layout />} />
            <Route exact path="/tournaments" element={<Layout />} />
            <Route exact path="/user-profile" element={<Layout />} />

            <Route exact path="/match/:videos/:matchId" element={<MatchContainer />} />
            <Route exact path="/match/:scorecard/:matchId" element={<MatchContainer />} />
            <Route exact path="/match/:analysis/:matchId" element={<MatchContainer />} />
            <Route exact path="/match/:awards/:matchId" element={<MatchContainer />} />
            <Route exact path="/match/:info/:matchId" element={<MatchContainer />} />
            <Route exact path="/match/internal/info/:matchId" element={<MatchJsonViewPage />} />
            <Route exact path="/internal/matches" element={<MatchesTablePage />} />
            <Route exact path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route exact path="/support" element={<SupportPage />} />
            <Route exact path="/cookies-policy" element={<Cookiespolicy />} />
            <Route exact path="/terms-of-service" element={<TermsAndConditions />} />
            <Route exact path="/delete-account" element={<DeleteAccount />} />
            <Route exact path="/delete-data" element={<DeleteData />} />
            <Route exact path="/about-us" element={<AboutUsPage />} />
            <Route exact path="/myysports/gb" element={<GullyBallInfo />} />
            <Route exact path="/team/:teamObjectId" element={<TeamProfilePage />} />
            <Route exact path="/player/:userName" element={<PlayerProfilepage />} />
            <Route exact path="internal/login" element={<LoginPage />} />

            <Route
              exact
              path="/dashboard"
              element={
                <PrivateRoute user={user} isAuthenticated={isAuthenticated}>
                  <DashBoardPage />
                </PrivateRoute>
              }
            />

            <Route path="*" element={<NotFoundPage />} />
          </Routes>
        </Suspense>
      </ErrorBoundary>
    </Router>
  );
};

export default App;
