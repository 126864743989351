import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import MainWidthWrapper from './MainWidthWrapper';
import { fetchAllMatches } from '../redux/match/match.action';
import { clearGetAllMatches, setSelectedDate } from './../redux/match/match.slice';
import CalendarView from './CalenderView';
import MatchCard from './ui/MatchCard';
import matchConfig from './config/matchcard.config';
import { toggleAuthWallOff, toggleAuthWallOn } from '../redux/auth/auth.slice';

const Match = () => {
  const [currentPage, setPage] = useState(1);
  const selectedDate = useSelector((state) => state.match.date);
  const dispatch = useDispatch();
  const getAllMatches = useSelector((state) => state.match.getAllMatches, shallowEqual);
  const { pageSize, totalPages } = useSelector((state) => state.match);
  const [hasFetchedInitial, setHasFetchedInitial] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const prevDateRef = useRef(null);

  useEffect(() => {
    setPage(1);
  }, [selectedDate]);

  useEffect(() => {
    if (currentPage <= totalPages && !isFetching) {
      setIsFetching(true);
      if (selectedDate === null) {
        dispatch(fetchAllMatches(currentPage, pageSize)).finally(() => setIsFetching(false));
      } else {
        dispatch(fetchAllMatches(currentPage, pageSize, selectedDate)).finally(() => setIsFetching(false));
      }
    }
  }, [dispatch, currentPage, pageSize, selectedDate]);

  const handleInfinityScroll = useCallback(() => {
    if (window.innerHeight + document.documentElement.scrollTop + 1 >= document.documentElement.scrollHeight) {
      setPage((prev) => prev + 1);
    }
  }, []);

  useEffect(() => {
    window.addEventListener('scroll', handleInfinityScroll);
    return () => window.removeEventListener('scroll', handleInfinityScroll);
  }, [handleInfinityScroll]);

  useEffect(() => {
    dispatch(toggleAuthWallOff());
    return () => {
      dispatch(toggleAuthWallOn());
      dispatch(clearGetAllMatches());
    };
  }, [dispatch]);

  const handleCalendarDateSelect = (date) => {
    setPage(1);
    dispatch(setSelectedDate(date));
  };

  // Ensure matchConfigData is an array
  const matchConfigData = Array.isArray(getAllMatches) ? getAllMatches.map((match) => matchConfig(match)) : [];

  return (
    <>
      <MainWidthWrapper>
        <div
          className="sticky top-[40px] z-[1000] bg-white px-2 "
          style={{ boxShadow: 'rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px' }}
        >
          <div className="flex items-center mb-3 ml-1">
            <span className="mr-2 text-sm font-semibold leading-[16.41px] text-left mt-[20px]">MATCHES</span>
            <hr className="flex-1 border-[0.5px] border-black mt-[15px]" />
          </div>
          <div className="flex justify-center w-full">
            <div className="w-full">
              <CalendarView setCurrentPage={setPage} onSelectDate={handleCalendarDateSelect} className="w-full" />
            </div>
          </div>
        </div>
        <div className="px-2 py-2">{getAllMatches && <MatchCard matches={matchConfigData} />}</div>
      </MainWidthWrapper>
    </>
  );
};

export default Match;
