import React, { useRef, useState, useEffect } from 'react';
import ReactPlayer from 'react-player';
import { Box } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlay } from '@fortawesome/free-solid-svg-icons';
import './css/player.css';

const VideoPlayer = ({ videoUrl, onPlayPause }) => {
  const [playing, setPlaying] = useState(false);
  const [loadedVideoUrl, setLoadedVideoUrl] = useState(null);
  const [videoError, setVideoError] = useState(false);
  const playerRef = useRef(null);

  const thumbnailUrl =
    'https://media.istockphoto.com/id/502037149/photo/cricket-action.jpg?s=612x612&w=0&k=20&c=zKWIx8c-5BGepNLpmWsQOoaNkZCEAdLr3KT_r8Y8maI=';

  const handleOverlayClick = (e) => {
    e.stopPropagation();
    setPlaying(true);
    setLoadedVideoUrl(videoUrl);
  };

  const handlePlayPause = (newPlayingState) => {
    setPlaying(newPlayingState);
    if (onPlayPause) {
      onPlayPause(newPlayingState);
    }
  };

  useEffect(() => {
    if (playerRef.current && loadedVideoUrl) {
      playerRef.current.seekTo(0, 'seconds');
    }
  }, [loadedVideoUrl]);

  const handleVideoError = () => {
    setVideoError(true);
  };

  return (
    <div className="video-player-wrapper">
      <div className="video-player-container" style={{ position: 'relative' }}>
        {videoError ? (
          <div className="video-unavailable-message">Video Unavailable</div>
        ) : (
          <Box sx={{ position: 'relative' }}>
            {!playing && (
              <div
                className="thumbnail-overlay"
                onClick={handleOverlayClick}
                style={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  width: '100%',
                  height: '100%',
                  backgroundImage: `url(${thumbnailUrl})`,
                  backgroundSize: 'cover',
                  backgroundPosition: 'center',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  cursor: 'pointer',
                }}
              >
                {loadedVideoUrl === null && (
                  <div className="play-button">
                    <FontAwesomeIcon icon={faPlay} size="3x" color="white" />
                  </div>
                )}
              </div>
            )}

            <ReactPlayer
              ref={playerRef}
              url={loadedVideoUrl}
              playing={playing}
              controls={true}
              width="100%"
              height="180px"
              onPlay={() => handlePlayPause(true)}
              onPause={() => handlePlayPause(false)}
              onError={handleVideoError}
              playIcon={<FontAwesomeIcon icon={faPlay} size="3x" color="white" />}
            />
          </Box>
        )}
      </div>
    </div>
  );
};

export default VideoPlayer;
