import React, { useState } from 'react';
import '../css/userInfo.css';
import Avatar from '../../assets/Avatar Fill.svg';
import QRCode from 'react-qr-code';
import { useSelector } from 'react-redux';
import User from '../../assets/user.png';

const UserInfo = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { user } = useSelector((state) => state.auth);
  const { userName, playerName, mobileNo, email, profilepicture, userObjId } = user || {};

  const profileUrl = `${process.env.REACT_APP_API_BASE_URL}player/${userName}`;

  const qrData = JSON.stringify({
    userName,
    playerName,
    mobileNo,
    email,
    profileUrl,
    userObjId,
  });

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  return (
    <div className="user-info-card">
      <div className="profile-pic">
        <img src={profilepicture || User} alt="Profile Picture" className="profile-img" />
        <div className="user-details">
          <div className="username">{playerName}</div>
          <div className="handle">@{userName}</div>
        </div>
      </div>

      <div className="qr-code" onClick={toggleModal}>
        <QRCode value={qrData} size={90} logoImage={Avatar} fgColor="#737EF9" bgColor="#FFFFFF" qrStyle="dots" />
      </div>

      {isModalOpen && (
        <div className="qr-modal-overlay" onClick={toggleModal}>
          <div className="qr-modal-content" onClick={(e) => e.stopPropagation()}>
            <button className="qr-modal-close" onClick={toggleModal}>
              X
            </button>
            <QRCode value={qrData} size={256} logoImage={Avatar} fgColor="#737EF9" bgColor="#FFFFFF" qrStyle="dots" />
          </div>
        </div>
      )}
    </div>
  );
};

export default UserInfo;
