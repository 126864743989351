import React from 'react';

const AccessDenied = () => {
  return (
    <div className="h-screen w-screen bg-white flex justify-center items-center">
      <div className="bg-gray-100 border border-gray-300 rounded-md p-6 shadow-md w-1/2 flex justify-center items-center">
        <h1 className="text-lg font-bold">ACCESS DENIED</h1>
      </div>
    </div>
  );
};

export default AccessDenied;
