// store.js
import { configureStore } from '@reduxjs/toolkit';
import matchReducer from './match/match.slice';
import videoReducer from './video/video.slice';
import tournamentReducer from './tournament/tournament.slice';
import authReducer from './auth/auth.slice';
import teamReducer from './team/team.slice';
const store = configureStore({
  reducer: {
    match: matchReducer,
    video: videoReducer,
    tournament: tournamentReducer,
    auth: authReducer,
    team: teamReducer,
  },
});

export default store;
