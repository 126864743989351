import Cookies from 'js-cookie';
import axios from 'axios';
import {
  setUser,
  setError,
  clearUser,
  setLoading,
  setUserProfile,
  setIsAuthenticated,
  setNextAuthAction,
  toggleAuthWallOff,
  toggleAuthWallOn,
  setFirebaseUser,
} from './auth.slice';
import api from '../../api/api';
export const signUp = (signupPayload) => {
  return async (dispatch) => {
    try {
      dispatch(setLoading(true));

      const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}api/v1.2.0/user/signup`, signupPayload);
      if (response.data?.data) {
        const userData = {
          userName: response?.data?.data?.newUser?.userName,
          playerName: response?.data?.data?.newProfile?.name?.firstName,
          mobileNo: response?.data?.data?.newProfile?.mobileNo?.primary,
          email: response?.data?.data?.newProfile?.email,
          userObjId: response?.data?.data?.newProfile?.userObjId,
          profilepicture: response?.data?.data?.newProfile?.profilePicture,
          permissions: response?.data?.data?.permissions,
        };
        dispatch(setUser(userData));
        if (response.data?.data?.accessToken) {
          Cookies.set('accessToken', response.data.data.accessToken, {
            secure: true,
            sameSite: 'Strict',
            expires: 1,
          });
          Cookies.set('refreshToken', response.data.data.refreshToken, {
            secure: true,
            sameSite: 'Strict',
            expires: 3,
          });
          dispatch(setIsAuthenticated(true));
          dispatch(toggleAuthWallOff());
        } else {
          dispatch(setIsAuthenticated(false));
        }
      }
    } catch (error) {
      dispatch(setError(error?.response?.message || error?.message));
    } finally {
      dispatch(setLoading(false));
    }
  };
};
export const login = (loginPayload) => {
  return async (dispatch) => {
    try {
      dispatch(setLoading(true));

      const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}api/v1/user/login`, loginPayload);
      if (response.data?.data) {
        const userData = {
          userName: response?.data?.data?.user?.userName,
          playerName: response?.data?.data?.user?.profileData?.name?.firstName,
          mobileNo: response?.data?.data?.user?.profileData?.mobileNo?.primary,
          email: response?.data?.data?.user?.profileData?.email,
          userObjId: response?.data?.data?.user?.profileData?.userObjId?._id,
          profilepicture: response?.data?.data?.user?.profileData?.profilePicture,
          permissions: response?.data?.data?.user?.permissions,
        };
        dispatch(setUser(userData));
        if (response.data?.data?.user?.accessToken) {
          Cookies.set('accessToken', response.data.data.user?.accessToken, {
            httpOnly: false,
            secure: true,
            sameSite: 'Strict',
            expires: 1,
          });
          Cookies.set('refreshToken', response.data.data.user?.refreshToken, {
            httpOnly: false,
            secure: true,
            sameSite: 'Strict',
            expires: 1,
          });
          dispatch(setIsAuthenticated(true));
          dispatch(toggleAuthWallOff());
        } else {
          dispatch(setIsAuthenticated(false));
        }
      }
    } catch (error) {
      dispatch(setError(error?.response?.message || error?.message));
    } finally {
      dispatch(setLoading(false));
    }
  };
};
export const existingUser = (payload) => {
  return async (dispatch) => {
    try {
      dispatch(setLoading(true));
      const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}api/v1/user/existingUser`, payload);
      const nextStep = response.data.data.nextStep;
      console.log(nextStep, 'nextStep');
      dispatch(setNextAuthAction(nextStep));
    } catch (error) {
      dispatch(setError(error?.response?.message || error?.message));
    } finally {
      dispatch(setLoading(false));
    }
  };
};

export const verifyAccessToken = () => {
  return async (dispatch) => {
    try {
      dispatch(setLoading(true));
      const response = await api.post(`${process.env.REACT_APP_API_BASE_URL}api/v0/user/verify-access-token`);
      if (response.data?.data) {
        const userData = {
          userName: response?.data?.data?.userName,
          playerName: response?.data?.data?.profileData?.name?.firstName,
          mobileNo: response?.data?.data?.profileData?.mobileNo?.primary,
          email: response?.data?.data?.profileData?.email,
          userObjId: response?.data?.data?.profileData?.userObjId?._id,
          profilepicture: response?.data?.data?.profileData?.profilePicture,
          permissions: response?.data?.data?.permissions,
        };
        dispatch(setUser(userData));
        dispatch(setIsAuthenticated(true));
        dispatch(toggleAuthWallOff());
      } else {
        dispatch(setIsAuthenticated(false));
        dispatch(toggleAuthWallOn());
      }
    } catch (error) {
      dispatch(setError(error?.response?.message || error?.message));
    } finally {
      dispatch(setLoading(false));
    }
  };
};

export const logout = () => {
  return async (dispatch) => {
    try {
      dispatch(setLoading(true));
      const response = await api.post(`${process.env.REACT_APP_API_BASE_URL}api/v0/user/logout`);
      Cookies.remove('accessToken');
      Cookies.remove('refreshToken');
      dispatch(setIsAuthenticated(false));
      dispatch(clearUser());
      dispatch(setFirebaseUser(null));
    } catch (error) {
      dispatch(setError(error?.response?.message || error?.message));
    } finally {
      dispatch(setLoading(false));
    }
  };
};

export const sendEmail = (reqPayload) => {
  return async (dispatch) => {
    try {
      dispatch(setLoading(true));

      const response = await api.post(`${process.env.REACT_APP_API_BASE_URL}api/send-email`, reqPayload);
      // set something in redux
    } catch (error) {
      dispatch(setError(error?.response?.message || error?.message));
    } finally {
      dispatch(setLoading(false));
    }
  };
};
