import React from 'react';
import { AppBar, Toolbar, Typography } from '@mui/material';
import gullyLogo from '../assets/gully ball _ logo.png';

const Header = () => {
  return (
    <AppBar position="sticky" style={{ backgroundColor: '#dee2e6' }}>
      <Toolbar>
        <img src={gullyLogo} alt="GullyBall" className="w-35 h-5 mx-auto" />
      </Toolbar>
    </AppBar>
  );
};

export default Header;
