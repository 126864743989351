import React from 'react';
import { Box } from '@mui/material';
import TeamUser from '../../assets/3 User.svg';

const TeamLogo = () => {
  return (
    <>
      <Box
        sx={{
          width: '60px', // Hug width
          height: '60px', // Hug height
          backgroundColor: '#495ECA',
          borderRadius: '12px',
          padding: '9.6px',
        }}
      >
        <Box
          component="img" // Use the img element
          src={TeamUser} // Your image URL
          alt="Team Logo"
          sx={{
            width: '40px', // Set image width
            height: '40px', // Set image height
            borderRadius: '8px', // Optional: add border-radius for rounded corners
            objectFit: 'cover', // Control how the image fills the container
            boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)', // Optional: add shadow
          }}
        />
      </Box>
    </>
  );
};

export default TeamLogo;
