import React from 'react';

const UserIcon = ({ fillColor, backColor }) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="Avatar Fill">
        <g clipPath="url(#clip0_4301_15826)">
          <rect x="1" y="1" width="22" height="22" rx="11" fill={backColor} />
          <g id="Icon">
            <mask
              id="mask0_4301_15826"
              style={{ maskType: 'alpha' }}
              maskUnits="userSpaceOnUse"
              x="1"
              y="1"
              width="22"
              height="22"
            >
              <circle id="Ellipse 1" cx="12" cy="12" r="11" fill={backColor} />
            </mask>
            <g mask="url(#mask0_4301_15826)">
              <path
                id="Profile Icon"
                d="M12.0032 15.2508H12.0037C16.2758 15.2508 20.0771 17.2727 22.5 20.4131V24.1931H1.5V20.4226C2.71909 18.8351 4.28302 17.5443 6.07447 16.6482C7.91513 15.7276 9.9452 15.2491 12.0032 15.2508ZM15.1685 9.24992C15.1685 10.0898 14.8349 10.8952 14.241 11.4891C13.6471 12.083 12.8417 12.4166 12.0018 12.4166C11.162 12.4166 10.3565 12.083 9.76266 11.4891C9.1688 10.8952 8.83517 10.0898 8.83517 9.24992C8.83517 8.41007 9.1688 7.60461 9.76266 7.01075C10.3565 6.41688 11.162 6.08325 12.0018 6.08325C12.8417 6.08325 13.6471 6.41688 14.241 7.01075C14.8349 7.60461 15.1685 8.41007 15.1685 9.24992Z"
                stroke={fillColor}
              />
            </g>
          </g>
        </g>
        <rect x="0.5" y="0.5" width="23" height="23" rx="11.5" stroke={fillColor} />
      </g>
      <defs>
        <clipPath id="clip0_4301_15826">
          <rect x="1" y="1" width="22" height="22" rx="11" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default UserIcon;
