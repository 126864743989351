import React, { useState } from 'react';
import { Box, IconButton } from '@mui/material';
import SortByAlphaIcon from '@mui/icons-material/SortByAlpha';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';

const IconToggle = ({ sortBy, setSortBy, sortOrder, setSortOrder }) => {
  const toggleAZ = () => {
    setSortBy('name');
    setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
  };

  const toggleCalendar = () => {
    setSortBy('createdAt');
    setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
  };

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '2px',
        gap: '1px',
        borderRadius: '10px',
        background: '#F9FAFB',
        border: '1px solid #DCE0E4',
        boxShadow: '1px 2px 5px 0px #0A0A0A14',
        width: '77px',
        height: '36px',
        cursor: 'pointer',
      }}
    >
      <IconButton
        sx={{
          width: '36px',
          height: '32px',
          padding: '8px 10px',
          borderRadius: '8px',
          background: sortBy === 'name' ? '#DCE0E4' : '#F9FAFB',
        }}
        onClick={toggleAZ}
      >
        <SortByAlphaIcon style={{ width: '20px', height: '18px' }} />
        {sortOrder === 'asc' && sortBy === 'name' ? (
          <ArrowDownwardIcon style={{ width: '16px', height: '21px' }} />
        ) : (
          <ArrowUpwardIcon style={{ width: '16px', height: '21px' }} />
        )}
      </IconButton>

      <IconButton
        sx={{
          width: '36px',
          height: '32px',
          padding: '8px 10px',
          borderRadius: '8px',
          background: sortBy === 'createdAt' ? '#DCE0E4' : '#F9FAFB',
        }}
        onClick={toggleCalendar}
      >
        <CalendarTodayIcon style={{ width: '16px', height: '16px' }} />
        {sortOrder === 'asc' && sortBy === 'createdAt' ? (
          <ArrowDownwardIcon style={{ width: '16px', height: '21px' }} />
        ) : (
          <ArrowUpwardIcon style={{ width: '16px', height: '21px' }} />
        )}
      </IconButton>
    </Box>
  );
};

export default IconToggle;
