import { createSlice } from '@reduxjs/toolkit';

const matchSlice = createSlice({
  name: 'match',
  initialState: {
    data: null,
    loading: true,
    error: null,
    allMatches: [],
    getAllMatches: [],
    userProfile: {},
    topPerformers: null,
    PartnershipRuns: null,
    RunsPerOver: null,
    mvp: null,
    currentPage: 1,
    pageSize: 25,
    totalMatches: null,
    date: null,
    totalPages: 1,
    isLive: false,
    isDummy: false,
    currScore: null,
    target: null,
    teamData: null,
    teamLoading: false,
    playerData: null,
    playerLoading: false,
    views: null,
    tournamentId: null,
    parternerShips: null,
    rpo: null,
    tournamentDetails: null,
    liveProbability: null,
    profileName: null,
    ExcludeWide: false,
    ExcludeNoBall: false,
  },
  reducers: {
    setMatchData: (state, action) => {
      state.data = action.payload.matchData;
      state.userProfile = action.payload.userProfile;
      state.topPerformers = action.payload.topPerformers;
      state.PartnershipRuns = action.payload.parternerShips;
      state.RunsPerOver = action.payload.rpo;
      state.mvp = action.payload.mvp;
      state.loading = false;
      state.error = null;
      state.isLive = action.payload.isMatchLive;
      state.isDummy = action.payload.isInternal;
      state.currScore = action.payload.currScore;
      state.tournamentId = action.payload.tournamentId;
      state.tournamentDetails = action.payload.tournamentDetails;
      state.ExcludeWide = action.payload.matchConfig.excludeWideBallRun;
      state.ExcludeNoBall = action.payload.matchConfig.excludeNoBallRun;
      state.profileName = action.payload.playerProfileDetails;
    },
    setMatchLoading: (state) => {
      state.loading = true;
    },
    setMatchError: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    setTotalMatches: (state, action) => {
      state.allMatches = action.payload.matchesInfo;
      state.getAllMatches = [...state.getAllMatches, ...action.payload.matchesInfo];
      state.totalMatches = action.payload.totalMatches;
      state.loading = false;
      state.totalPages = action.payload.totalPages || 1;
    },
    setCachedMatches: (state, action) => {
      if (!state.cachedMatches) {
        state.cachedMatches = {};
      }
      if (!state.cachedMatches[action.payload.date]) {
        state.cachedMatches[action.payload.date] = {};
      }
      state.cachedMatches[action.payload.date][action.payload.page] = action.payload.matches;
    },
    setCurrentPageAllMatches: (state, action) => {
      state.currentPage = action.payload;
    },
    setPageSizeAllMatches: (state, action) => {
      state.pageSize = action.payload;
    },
    setSelectedDate: (state, action) => {
      state.date = action.payload ?? null;
    },
    clearGetAllMatches: (state, action) => {
      state.getAllMatches = [];
    },
    setCurrScoreOnRealTimeUpdate: (state, action) => {
      state.currScore = action.payload.currScore;
    },
    setIsLive: (state, action) => {
      state.isLive = action.payload;
    },
    setTeamData: (state, action) => {
      state.teamData = action.payload;
      state.teamLoading = false;
    },
    setTeamLoading: (state) => {
      state.teamLoading = true;
    },
    setTeamError: (state, action) => {
      state.error = action.payload;
      state.teamLoading = false;
    },
    setPlayerData: (state, action) => {
      state.playerData = action.payload;
      state.playerLoading = false;
    },
    setPlayerLoading: (state) => {
      state.playerLoading = false;
    },
    setPlayerError: (state, action) => {
      state.error = action.payload;
      state.playerLoading = false;
    },
    setViews: (state, action) => {
      state.views = action.payload;
    },
    setcurrPartnerships: (state, action) => {
      state.parternerShips = action.payload;
    },
    setcurroverdetails: (state, action) => {
      state.rpo = action.payload;
    },
    setliveProbability: (state, action) => {
      state.liveProbability = action.payload;
    },
  },
});

export const {
  setMatchData,
  setMatchLoading,
  setMatchError,
  setTotalMatches,
  setCurrentPageAllMatches,
  setPageSizeAllMatches,
  setSelectedDate,
  clearGetAllMatches,
  setCurrScoreOnRealTimeUpdate,
  setIsLive,
  setTeamData,
  setTeamLoading,
  setTeamError,
  setPlayerData,
  setPlayerLoading,
  setPlayerError,
  setViews,
  setcurrPartnerships,
  setcurroverdetails,
  setliveProbability,
} = matchSlice.actions;
export default matchSlice.reducer;
