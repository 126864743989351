import React, { useState } from 'react';
import moment from 'moment';
import Chip from '@mui/material/Chip';
import { Link } from 'react-router-dom';
import InfoIcon from '@mui/icons-material/Info';
import ShareIcon from '@mui/icons-material/Share';
import VisibilityIcon from '@mui/icons-material/Visibility';
import FavoriteIcon from '@mui/icons-material/Favorite';
import { Box, Typography } from '@mui/material';
import { RWebShare } from 'react-web-share';
import InfoModal from './modals/InfoModal';
import { useSelector } from 'react-redux';
import { RemoveRedEye as RemoveRedEyeIcon } from '@mui/icons-material';

const Result = ({ matchData }) => {
  const { isLive, currScore, views } = useSelector((state) => state.match);
  const videoData = useSelector((state) => state.video);
  const videoTitle = videoData?.currentPlayingVideo
    ? videoData.currentPlayingVideo.category === 'HIGHLIGHT_SUMMARY'
      ? 'Highlight Video'
      : videoData.currentPlayingVideo.category === 'FULL_MATCH_VIDEO'
      ? 'Full Match Video'
      : videoData.currentPlayingVideo.caption || ''
    : '';

  const hasVideos =
    videoData?.individualHiglightClipsList?.length !== 0 || videoData?.summaryAndFullMatchVideosList?.length !== 0;

  const [open, setOpen] = useState(false);

  const { info, matchId } = matchData[0] || {};
  const { date, numOfPlayerInEachTeam, limitedOver, result, innings } = info?.[0] || {};
  const matchDate = moment(date).utc().format('dddd DD MMMM YYYY, h:mm A');
  const format = `Gully ${numOfPlayerInEachTeam}X${limitedOver}`;
  const resultData = result;
  const handleOpen = () => setOpen(true);
  const battingTeam = currScore?.battingTeam;
  const firstInning = currScore?.showFirstInningScore;
  const teamName1 = matchData?.[0]?.info?.[0]?.teams?.[0]?.teamName;
  const teamName2 = matchData?.[0]?.info?.[0]?.teams?.[1]?.teamName;
  const showScoreCardTeam1 = currScore?.teamScore?.[teamName1]?.showScoreCard;
  const showScoreCardTeam2 = currScore?.teamScore?.[teamName2]?.showScoreCard;
  const Toss = matchData?.[0]?.info?.[0]?.tossWinner;
  const maxLength = 12;
  const truncateLongestWord = (text, maxLength) => {
    if (!text) return text;
    const words = text.split(' ');
    let longestWordIndex = 0;
    let longestWordLength = 0;
    // Find the longest word's index and length
    for (let i = 0; i < words.length; i++) {
      if (words[i].length > longestWordLength) {
        longestWordIndex = i;
        longestWordLength = words[i].length;
      }
    }
    // Truncate only the longest word if it exceeds maxLength
    if (longestWordLength > maxLength) {
      words[longestWordIndex] = words[longestWordIndex].slice(0, maxLength) + '...';
    }
    return words.join(' ');
  };
  const truncatedResultType =
    resultData && resultData[0]?.result_type ? truncateLongestWord(resultData[0].result_type, maxLength) : '';
  const truncatedFirstInningScore =
    firstInning && currScore?.firstInningScore ? truncateLongestWord(currScore.firstInningScore, maxLength) : '';

  const isBattingTeam = (teamName) => {
    return isLive && battingTeam === teamName;
  };
  const getOversPlayedByTeam = (teamName) => {
    return innings?.find(({ battingTeam }) => battingTeam === teamName)?.inningOver || 0;
  };

  return (
    <>
      <InfoModal setOpen={setOpen} open={open} matchId={matchId} />
      <Box sx={{ width: '100%', bgcolor: 'black', color: '#fff', pt: 1 }}>
        <Box
          sx={{
            px: 2,
            color: '#808080',
            mb: '10px',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Typography
            variant="body2"
            sx={{ fontSize: { sm: hasVideos ? '20px' : '16px', xs: hasVideos ? '14px' : '10px' }, mr: 5, flex: 1 }}
          >
            {hasVideos ? videoTitle : matchDate}
          </Typography>
          <Box sx={{ display: 'flex', columnGap: 2 }}>
            {/* <Box sx={{ display: 'flex', alignItems: 'center', gap: '1px' }}>
              <FavoriteIcon sx={{ fontSize: '20px' }} /> <Typography variant="body2">0</Typography>
            </Box> */}

            <Box sx={{ display: 'flex', alignItems: 'center', gap: '1px' }}>
              <RemoveRedEyeIcon sx={{ fontSize: '20px' }} /> <Typography variant="body2">{views?.totalViews}</Typography>
            </Box>

            {/* <FavoriteIcon  sx={{ fontSize: '24px', cursor: 'pointer'}}/>
             <VisibilityIcon  sx={{ fontSize: '24px', cursor: 'pointer'}}/> */}
            <Box sx={{ display: 'flex', alignItems: 'center', gap: '1px' }}>
              <RWebShare
                data={{
                  text: 'Lets Watch together',
                  url: window.location,
                  title: 'Gully Cricket',
                }}
                sites={['facebook', 'twitter', 'whatsapp', 'reddit', 'linkedin', 'mail']}
              >
                <ShareIcon sx={{ fontSize: '20px', cursor: 'pointer' }} />
              </RWebShare>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: '1px' }}>
              <InfoIcon sx={{ fontSize: '20px', cursor: 'pointer' }} onClick={() => handleOpen(true)} />
            </Box>
          </Box>
        </Box>
        <Box>
          <Box
            sx={{
              mx: 2,
              display: 'flex',
              pb: 2.5,
              borderBottom: '1px solid #D6D5D566',
            }}
          >
            <Box
              sx={{
                textAlign: 'left',
                width: '9rem',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
                color: '#ffffff',
                flex: 1,
              }}
            >
              <Box
                sx={{
                  color: '#FF6161',
                  fontSize: '12px',
                  textTransform: 'uppercase',
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                  textOverflow: 'clip',
                  '@media screen and (max-width: 600px)': {
                    textOverflow: 'ellipsis',
                    fontSize: '12px',
                  },
                }}
              >
                <Link
                  to={`/team/${matchData[0].info[0].teams[0].teamObjectId}`}
                  style={{ textDecoration: 'none', color: 'inherit' }}
                >
                  {matchData[0]?.info[0]?.teams[0]?.teamName}
                </Link>
              </Box>
              {isLive ? (
                showScoreCardTeam1 ? (
                  <Box
                    sx={{
                      color: 'rgb(255, 255, 255)',
                      fontWeight: 'bold',
                      marginBottom: '5px',
                      fontSize: '14px',
                    }}
                  >
                    {`${currScore?.['teamScore']?.[matchData?.[0]?.info?.[0]?.teams?.[0]?.teamName]?.['runs'] ?? ''}/${
                      currScore?.['teamScore']?.[matchData?.[0]?.info?.[0]?.teams?.[0]?.teamName]?.['wickets'] ?? ''
                    }`}
                  </Box>
                ) : (
                  <Box
                    sx={{
                      fontSize: {
                        xs: '12px',
                        md: '14px',
                      },
                    }}
                  >
                    YET TO BAT
                  </Box>
                )
              ) : resultData ? (
                <Box
                  sx={{
                    color: 'rgb(255, 255, 255)',
                    fontWeight: 'bold',
                    marginBottom: '5px',
                  }}
                >
                  {`${resultData[0]['t1i1_score']}/${resultData[0]['t1i1_wicket']}`}
                </Box>
              ) : (
                <Box sx={{ fontSize: '12px' }}>0 / 0</Box>
              )}

              <Box sx={{ fontSize: '12px' }}>
                {isLive
                  ? `${
                      currScore?.['teamScore']?.[matchData?.[0]?.info?.[0]?.teams?.[0]?.teamName]?.['overs'] || '0.0'
                    }/${limitedOver} ov`
                  : `${getOversPlayedByTeam(matchData?.[0]?.info?.[0]?.teams?.[0]?.teamName)}/${limitedOver} ov`}
              </Box>
            </Box>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                alignItems: 'center',
                borderRadius: '14px',
                width: { xs: '150px', sm: '150px' },
              }}
            >
              <Chip
                label={format}
                sx={{
                  backgroundColor: '#1F67FF',
                  borderRadius: '2px',
                  color: '#FFFFFF',
                  fontSize: '10px',
                  mb: '8px',
                  height: '20px',
                  width: { xs: '75px', sm: '75px' },
                }}
              />
              {resultData && (
                <>
                  {firstInning ? (
                    <Typography
                      sx={{
                        color: '#F2D875',
                        textAlign: 'center',
                        overflow: 'hidden',
                        whiteSpace: 'normal',
                        width: '100%',
                        textTransform: 'uppercase',
                        fontWeight: 600,
                        fontSize: '12px',
                        lineHeight: '14.06px',
                      }}
                    >
                      {truncatedFirstInningScore}
                    </Typography>
                  ) : (
                    <>
                      {resultData[0]?.result_type && (
                        <Typography
                          sx={{
                            color: '#F2D875',
                            textAlign: 'center',
                            overflow: 'hidden',
                            whiteSpace: 'normal',
                            width: '100%',
                            textTransform: 'uppercase',
                            fontWeight: 600,
                            fontSize: '12px',
                            lineHeight: '14.06px',
                          }}
                        >
                          {truncatedResultType}
                        </Typography>
                      )}
                      {isLive && currScore['target']?.['uiTargetText'] && (
                        <Typography
                          sx={{
                            color: '#F2D875',
                            textAlign: 'center',
                            overflow: 'hidden',
                            whiteSpace: 'normal',
                            width: '100%',
                            textTransform: 'uppercase',
                            fontWeight: 600,
                            fontSize: '12px',
                            lineHeight: '14.06px',
                          }}
                        >
                          {currScore['target']['uiTargetText']}
                        </Typography>
                      )}
                    </>
                  )}
                </>
              )}
            </Box>
            <Box
              sx={{
                textAlign: 'right',
                width: '9rem',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
                color: '#ffffff',
                flex: 1,
              }}
            >
              <Box
                sx={{
                  color: '#9A7BF8',
                  fontSize: '12px',
                  textTransform: 'uppercase',
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                  textOverflow: 'clip',
                  '@media screen and (max-width: 600px)': {
                    textOverflow: 'ellipsis',
                    fontSize: '12px',
                  },
                }}
              >
                <Link
                  to={`/team/${matchData[0].info[0].teams[1].teamObjectId}`}
                  style={{ textDecoration: 'none', color: 'inherit' }}
                >
                  {matchData[0]?.info[0]?.teams[1]?.teamName}
                </Link>
              </Box>
              {isLive ? (
                showScoreCardTeam2 ? (
                  <Box
                    sx={{
                      color: 'rgb(255, 255, 255)',
                      fontWeight: 'bold',
                      marginBottom: '5px',
                    }}
                  >
                    {`${currScore?.['teamScore']?.[matchData?.[0]?.info?.[0]?.teams?.[1]?.teamName]?.['runs'] ?? ''}/${
                      currScore?.['teamScore']?.[matchData?.[0]?.info?.[0]?.teams?.[1]?.teamName]?.['wickets'] ?? ''
                    }`}
                  </Box>
                ) : (
                  <Box
                    sx={{
                      fontSize: {
                        xs: '12px',
                        md: '14px',
                      },
                    }}
                  >
                    YET TO BAT
                  </Box>
                )
              ) : resultData ? (
                <Box
                  sx={{
                    color: 'rgb(255, 255, 255)',
                    fontWeight: 'bold',
                    marginBottom: '5px',
                  }}
                >
                  {`${resultData[0]['t2i1_score']}/${resultData[0]['t2i1_wicket']}`}
                </Box>
              ) : (
                <Box sx={{ fontSize: '12px' }}>0 / 0</Box>
              )}

              <Box sx={{ fontSize: '12px' }}>
                {isLive
                  ? `${
                      currScore?.['teamScore']?.[matchData?.[0]?.info?.[0]?.teams?.[1]?.teamName]?.['overs'] || '0.0'
                    }/${limitedOver} ov`
                  : `${getOversPlayedByTeam(matchData?.[0]?.info?.[0]?.teams?.[1]?.teamName)}/${limitedOver} ov`}
              </Box>
            </Box>
          </Box>
        </Box>

        {/* {topPerformers && !isLive ? (
          <Box>
            <TopPerformer topPerformers={topPerformers} />
          </Box>
        ) : null} */}
      </Box>
    </>
  );
};

export default Result;
